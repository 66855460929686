import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PrivacyModal from './PrivacyModal';

const ConfirmationModal = ({ show, onHide, onConfirm, title, message }) => {
  const [showP, setShowP] = useState(false);
  const onHideP = () => {
    setShowP(false);
  }

  const onShowPopup = () => {
    setShowP(true);
  }
  return (
    <>
    <Modal show={show} onHide={onHide} centered>
      {title != "" && (
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "18px", fontFamily: "Arial, sans-serif", color: "#055837" }}>
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="text-center" style={{ fontSize: "16px", fontFamily: "Arial, sans-serif" }}>
        {/* {message} */}
        <p dangerouslySetInnerHTML={{ __html: message }} />
        <p>
        <hr />
        View our <a className='custom_blue_link' onClick={ () => {
          onShowPopup()
        }
        }>Privacy Collection Notice</a> to learn how we manage your personal information collected for the skills assessment application.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="light"
          onClick={onHide}
          className="w-50"
          style={{
            maxWidth: "130px",
            backgroundColor: "#dc3545",
            color: "white",
            transition: "transform 0.2s",
          }}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
        >
          No
        </Button>
        <Button
          variant="light"
          onClick={onConfirm}
          className="w-50"
          style={{
            maxWidth: "130px",
            backgroundColor: "#055837",
            color: "#ffcc01",
            transition: "transform 0.2s",
          }}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>

    <PrivacyModal 
    show = {showP}
    onHide={onHideP}
    />
    </>
  );
};

export default ConfirmationModal;
