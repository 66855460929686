import React from "react";
import "./Footer.css";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="footer">
      <Row>
        <Col lg={6} className="my-auto">
        
        <h6 className="float-start pl-2">
          Copyright © 2024 Australian Qualifications & Training Overseas. All
          Rights Reserved.
        </h6>
        </Col>
        <Col lg={6}>
        <div className="footer_bar float-end">
          <ul>
            <li
            >
              <a href="https://aqato.com.au/terms-and-conditions/" target="_blank">
              <u>
                Terms and Conditions
              </u>
              </a>
            </li>
            <li>
              <a href="https://aqato.com.au/privacy-policy/" target="_blank">
              <u>
                Privacy Policy
              </u>
              </a>  
            </li>
            <li>
              <a href="https://aqato.com.au/disclaimer/" target="_blank">
              <u>
                Disclaimer
              </u>
              </a>
            </li>
          </ul>
        </div>

        </Col>
      </Row>
      
    </div>
  );
};

export default Footer;
